import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div id="footer-content">
                    <div>Copyright 2021 Galloping Dog Agility Club</div>
                    <div><a href="https://www.facebook.com/GallopingDogAgilityClub">Facebook</a></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer