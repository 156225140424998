import React from "react"
import Menu from './mobile-menu'

class Navigation extends React.Component {

  render() {
    return (
        
      <>
        <div className="menuicons" role="button" onClick={() => this.toggleMenu()}>
                          <div />
                          <div />
                          <div />
        </div>
        {/* <button onClick={() => this.toggleMenu()}>
          Open Menu
        </button> */}

        <Menu ref={el => (this.childMenu = el)} />
      </>
    )
  }

  toggleMenu() {
    this.childMenu.open()
  }
}

export default Navigation