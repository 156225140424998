import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import '../styles/main.scss'
import Navbar from './navigation'

const Header = () => {
    return (
    <StaticQuery
        query = {
            graphql`
                query HeaderQuery {
                    prismicHeader {
                        data {
                            logo {
                                url
                                alt
                            }
                            menu_links {
                                label {
                                    text
                                }
                                link {
                                    url
                                    raw
                                    id
                                }
                            }
                        }
                    }
                }
            `
        }
        render = { data => (
            <header>
                <Link to='/' className="header-logo">
                    <img src={data.prismicHeader.data.logo.url} alt={data.prismicHeader.data.logo.alt} />
                </Link>
                <nav className="desktop-menu">
                    <ul>
                        {data.prismicHeader.data.menu_links.map((item, index) => (
                            <li key={`top-nav-${index}`}>
                                <Link to={item.link.url}>
                                    {item.label.text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="mobile-menu">
                    <Navbar />
                </div>
            </header>
        )}
    />
    )
}

export default Header