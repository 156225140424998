import React, { useImperativeHandle, useRef } from "react"
import { StaticQuery, graphql, Link} from 'gatsby'


class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <StaticQuery
        query = {
            graphql`
                query MenuQuery {
                    prismicHeader {
                        data {
                            logo {
                                url
                                alt
                            }
                            menu_links {
                                label {
                                    text
                                }
                                link {
                                    url
                                    raw
                                    id
                                }
                            }
                        }
                    }
                }
            `
        }
        render = { data => (
          <div
            role="button"
            tabIndex="0"
            className={`mobilemenu ${this.state.open ? `open` : ""}`}
            onClick={() => this.close()}
            onKeyDown={() => this.close()}
          >
            <div className="menuicons">
              <div />
              <div />
              <div />
            </div>
            <nav className="mobile-nav">
                <ul>
                    {data.prismicHeader.data.menu_links.map((item, index) => (
                        <li key={`top-nav-${index}`}>
                            <Link to={item.link.url}>
                                {item.label.text}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
          </div>
        )}
        />
    )
  }

  close() {
    this.setState({ open: false })
  }

  open() {
    this.setState({ open: true })
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})